<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="分类" prop="type">
                <a-select placeholder="请选择分类" style="width: 100%" v-model="queryParam.categoryId" allowClear>
                  <a-select-option v-for="category in categoryList" :value="category.id">
                    {{category.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商铺编号" prop="shopNo">
                <a-input v-model="queryParam.shopNo" placeholder="请输入商铺编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="开始时间" prop="startTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.startTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="结束时间" prop="endTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.endTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="是否上架" prop="isSale">
                  <a-select placeholder="请选择类型" style="width: 100%" v-model="queryParam.isSale" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="可参与用户类型" prop="userType">
                  <a-select placeholder="请选择可参与用户类型" style="width: 100%" v-model="queryParam.userType" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.AuthTypeEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['res:shop:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['res:shop:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['res:shop:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['res:shop:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="isSale" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isSale"/>
        </span>
        <span slot="userType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.AuthTypeEnum" :value="record.userType"/>
        </span>
        <span slot="shopStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.ShopStatusEnum" :value="record.shopStatus"/>
        </span>
        <template slot="masterImg" slot-scope="text, record">
          <div>
            <img v-if="record.masterImg" :src="record.masterImg"  style="width:60px;height:62px;" preview="handlePreview"/>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['res:shop:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['res:shop:edit']">
            <a-icon type="edit" />修改
          </a>
           <a-divider type="vertical" v-hasPermi="['res:shop:edit']" />
          <a @click="$refs.createForm.handleCopy(record, undefined)" v-hasPermi="['res:shop:edit']">
            <a-icon type="edit" />复制
          </a>
          <a-divider type="vertical" v-hasPermi="['res:shop:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['res:shop:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageShop,listShop, delShop } from '@/api/res/shop'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listCategory} from "@/api/res/category";

export default {
  name: 'Shop',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      categoryList: [],//分类
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopNo: null,
        userType:null,
        shopName: null,
        categoryId: null,
        shopDesc: null,
        shopImg: null,
        startTime: null,
        endTime: null,
        rentNum: null,
        startAmount: null,
        addAmount: null,
        promiseAmount: null,
        currentAmount: null,
        isSale: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '商铺编号',
          dataIndex: 'shopNo',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'shopName',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'shopStatus',
          scopedSlots: { customRender: 'shopStatus' },
          align: 'center'
        },
        {
          title: '可参与用户类型',
          dataIndex: 'userTypeListName',
          align: 'center'
        },
        {
          title: '分类名称',
          dataIndex: 'categoryName',
          align: 'center'
        },
        {
          title: '商铺主图',
          dataIndex: 'masterImg',
          scopedSlots: { customRender: 'masterImg' },
          align: 'center'
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'startTime' },
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'endTime' },
          align: 'center'
        },
        {
          title: '竞租者人数',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'rentNum',
          align: 'center'
        },
        {
          title: '起始价',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'startAmount',
          align: 'center'
        },
        {
          title: '加价幅度',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'addAmount',
          align: 'center'
        },
        {
          title: '保证金',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'promiseAmount',
          align: 'center'
        },
        {
          title: '成交价格',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'currentAmount',
          align: 'center'
        },
       /* {
          title: '是否上架',
          dataIndex: 'isSale',
          scopedSlots: { customRender: 'isSale' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询res-商铺列表 */
    getList () {
      this.loading = true
     pageShop(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
    },
    getCategoryList () {
      listCategory({level: 1,type:20}).then(response => {
        this.categoryList = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopNo: undefined,
        shopName: undefined,
        categoryId: undefined,
        shopDesc: undefined,
        shopImg: undefined,
        startTime: undefined,
        endTime: undefined,
        rentNum: undefined,
        startAmount: undefined,
        addAmount: undefined,
        promiseAmount: undefined,
        currentAmount: undefined,
        isSale: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delShop(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('res/shop/export', {
            ...that.queryParam
          }, `res-商铺_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
